import Image from 'next/image'

import * as style from './vehicleInfo.css'

interface Props {
  carNumber: string | null
  vehicleType: string
  fuelType: string
  parking: boolean
}

function VehicleInfo(props: Props) {
  const { carNumber, vehicleType, fuelType, parking } = props

  return (
    <button className={style.wrapper}>
      <div className={style.carInfo}>
        <p className={`${style.carInfo} ${carNumber ? 'number' : 'text'}`}>
          {carNumber ? carNumber : '차량번호 등록하기'}
        </p>
        <span>
          {vehicleType ? <>{vehicleType}&nbsp;&nbsp;|&nbsp;&nbsp;</> : null}
          {fuelType ? <>{fuelType}&nbsp;&nbsp;|&nbsp;&nbsp;</> : null}
          {parking ? <>티맵주차</> : null}
        </span>
      </div>
      <Image
        src={require('../../../../../public/assets/images/ico_arrow_right.svg').default}
        alt=""
      />
    </button>
  )
}

export { VehicleInfo }
