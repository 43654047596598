import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { CIResData, getHasCi } from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'

import { KEYS } from '@/react-query'

function useGetHasCi(): UseQueryResult<CIResData, AxiosError> {
  return useQuery<CIResData, AxiosError>([KEYS.USER_HAS_CI()], () =>
    getHasCi('$userKey').then(({ data }) => data)
  )
}

export { useGetHasCi }
