import "../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@18.19.43_sass@1.77.8_terser@5.31.4_webpack@5.93.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ffeatures%2Fweb%2Fcomponents%2Fplace-review%2FplaceReview.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA81WXY%2BbOhB9z6%2BwKl0peXAWkmyakpf7T1aDbcBdY3Ntk5Bb7X%2BvbD5iSEjTah8qXsCewTPnnBnP%2Bi3OoP5vTyL0Y4FQCTrnMkGbXdWgTVQ1aLupmuPiY7HuDWNvSLmpBFwSlAnWHBcIfa%2BN5dkFEyUtkzZBpgLCcMrsmTF5HP6NU2WtKhMU%2Bx8jlClp8ZnxvLAJOoFeYhws4VQJuhrsDP%2BfjazcAo73q0mIw%2BvGR0uUULr3s6yxOAXDCSZQWa7k2Hv7ZIKEScu02wLBc4m5ZaUJlx9FvPM5zcVFmSGa97H9EiOpdAlikkWSQGaZ7vLvYv7y5RjmlgpF3t3KmVNbJCjet5wU3VH9dwrkPdeqlhTzEnKWoFqL5QsYw6x58UvmhRP1Blqr85t23mtzylcT5xYMok4tQMGOZhUDmyCputdRMjufRWA%2BBq5UKcepqBmOo2gV5hNF%2F4T5fIvafD6B3FRpyjTWQHlteqSCkF%2F%2FtExmzpvJ6LVjqC9cV1UocisVUMplnqD40NXyNewEGSU4RXHVdBjmGi4DetPcukr9LbU%2B0H2A0t6j1OVGQJClSxBh9NXlgf3Rq%2BcYcxuYSfoAw64DCZbZPqtRLJSf7rE2tUkEGItVhu2lYo5AOeqdkX92d%2F%2FujJOM68B%2F7Oq4unX%2BGlhh3TJ%2FuENKwGLgDd67UoY7ihKkmQDLT%2ByOrAbRuLbfXgC9cjzhHtjbMhj0tN4NigqqcvtYV6lqsCmAqnOC3In7qul0PPwsFUDeMYiqABxHn9k7S0Z5Xf5St68TSB901yvQkBolauuBtqpK2sJs5edfVQWE20v3ZTXI3rXbQdF6YxADwybHA3EMhlHcP3ambfTfQwDx8UGDDTt4rTPXsKyqSYEt6JzZeW5HIqwSaYte9svNKtS0R%2BcQDAQzNByeoXQYGeYlkkEt7JjSNGxFGbd9ZxmVhZP3YdJx3RrUVl177AyMvjpfH6v3OpE8BmJzD%2FTN7qmR6t64QMLsb7rBUKnPCIQQZgw2jChJQV9u071j7Shj%2Bl5Oh4mO6AOaPuFaHwbgqGd12%2BUeauC56e75zvNcLwtR%2BKPpbjeZ7nZ%2Fx3RHr3foHBBAHEW%2FUfwfi38dvoAM0YxJBJKiZQkN7rDYOoLbFnS9ZccXu9sL0JRK%2Bo76EbocrqEjdC64ZdgPdM78rKE6%2Bg2HSCbc9VZwStsxr7tQr1tMCF4ZbtrNYSK4OfLbfGAfPwFv7eCvzg0AAA%3D%3D%22%7D"
export var banner = '_1fauq6c4';
export var btn_more = '_1fauq6c3';
export var count = '_1fauq6c2';
export var lastReaction = '_1fauq6c8';
export var lastReactionImage = '_1fauq6c9';
export var moreReviewBtn = '_1fauq6cd';
export var pipe = '_1fauq6c7';
export var reactionWrapper = '_1fauq6c5';
export var reactions = '_1fauq6c6';
export var reviewBtn = '_1fauq6cc';
export var reviewModuleWrapper = '_1fauq6ca';
export var title = '_1fauq6c1';
export var tripDateTag = '_1fauq6cb';
export var wrapper = '_1fauq6c0';