import { formatCommaNumber } from '@tmap-web-lib/utils'
import Image from 'next/image'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import Tooltip from '@/components/Tooltip/Tooltip'
import { setToolTipClicked } from '@/features/store/settings'
import { useOpenService } from '@/hooks'
import { schemeUrl } from '@/utils'

import * as styles from './userProfile.css'

interface UserProfileProps {
  profileImage: string | null
  name: string
  point: number
  carNumber: string
  isVerified: boolean | null
  carInfoTags?: string[]
}

export function UserProfile(props: UserProfileProps) {
  const { profileImage, name, point, carNumber, isVerified, carInfoTags } = props
  const openService = useOpenService()
  const dispatch = useDispatch()
  const [hasRedDot, setHasRedDot] = useState(true)

  const moveToProfileEdit = useCallback(() => {
    dispatch(setToolTipClicked(true))
    openService(schemeUrl.profile)
  }, [openService, dispatch])

  const moveToPoint = useCallback(() => {
    openService(schemeUrl.point)
  }, [openService])

  return (
    <div className={styles.wrapper}>
      <Tooltip content={'프로필을 설정하고 나를 표현해보세요!'}>
        <button onClick={moveToProfileEdit}>
          <Image
            style={{ width: '56px', height: '56px' }}
            src={require('../../../../assets/images/driver2.svg').default}
            alt=""
          />
        </button>

        <div className={styles.userInfoWrapper}>
          <button
            className={styles.nameBtn}
            onClick={moveToProfileEdit}
          >
            <p
              className={`${styles.name} ${isVerified ? styles.nameWidthVariants.verified : styles.nameWidthVariants.notVerified}`}
            >
              {name}
            </p>
            {isVerified !== null && !isVerified && (
              <div className={styles.unVerifiedTag}>미인증</div>
            )}
            <Image
              src={require('../../../../assets/images/ico_pen_write.svg').default}
              alt=""
            />
          </button>
          <button
            className={hasRedDot ? styles.userPointRedDot : styles.userPoint}
            onClick={moveToPoint}
          >
            {point > 0 ? `${formatCommaNumber(point)} P` : '내 포인트'}
          </button>
        </div>
      </Tooltip>
    </div>
  )
}
