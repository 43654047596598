import { AdType } from '@tmap-web-lib/remote-api-client/frontman'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { usePageIdContext } from '@/Providers'
import { TmapLogContextProvider, useTmapLogContext } from '@/Providers/tmap-log-context'
import { MainSkeleton } from '@/components'
import { store, useAppDispatch } from '@/features/store'
import {
  STORAGE_SCHEME_URL,
  getSettingsState,
  setIsSkeleton,
  setSchemeURL,
} from '@/features/store/settings'
import {
  AdsBanner,
  PlaceReview,
  ServiceList,
  UserProfile,
  VehicleInfo,
} from '@/features/web/components'
import { useOpenService } from '@/hooks'
import { useGetAdsServing, useGetHasCi } from '@/react-query'
import { storageUtil } from '@/utils'

import * as styles from '../../styles/pages/web.css'

const mockData = {
  profileImage: null,
  name: '티모비티모비티모비티모비티모비티모비티모비티모비티모비티모비티모비티모비',
  point: 57000,
  carNumber: '123가1234',
  isVerified: false,
  carInfoTags: ['승용차', '휘발유', '티맵주차'],
}

const mockReviewData = {
  code: 200,
  message: 'success',
  data: {
    needPopup: true,
    reviewCount: 12,
    activeReviewCount: 1,
    reviewReaction1: 8,
    reviewReaction2: 2,
    reviewReaction3: 1,
    review: {
      tripId: '267680843730110710138S00',
      tripDateTime: '24.09.08',
      availableTripReview: true,
      poiId: 3048537,
      poiName: '춘천명물닭갈비',
      reviewId: null,
      newReview: true,
      reviewGuide: '7일 뒤 저장 내용이 사라져요',
    },
  },
  originalNickName: 'NICKNAME',
  userStatus: 'ACTIVE',
}
export default function Web() {
  const dispatch = useAppDispatch()
  const { accessKey, schemeURL } = useSelector(getSettingsState)
  const { tmapLogClient } = useTmapLogContext()
  const pageIdContext = usePageIdContext()
  const openService = useOpenService
  const { data: hasCIData, isInitialLoading: isCIDataLoading } = useGetHasCi()
  const { data: bannerList, isInitialLoading: isBannerLoading } = useGetAdsServing({
    inventoryCode: 'IN104',
    useErrorBoundary: false,
  })

  const [isVerified, setIsVerified] = useState<boolean | null>(null)
  const [bannerData, setBannerData] = useState<AdType[] | null>(null)
  const [isMainLoading, setIsMainLoading] = useState(true)

  useEffect(() => {
    if (hasCIData) {
      if (hasCIData.detailCode === 'SUCCESS' && hasCIData.hasUserCi) {
        setIsVerified(true)
      } else {
        setIsVerified(false)
      }
    }
  }, [hasCIData])

  useEffect(() => {
    if (bannerList) {
      setBannerData(bannerList.ads)
    }
  }, [bannerList])

  useEffect(() => {
    if (!isCIDataLoading && !isBannerLoading) {
      setIsMainLoading(false)
    }
  }, [isCIDataLoading, isBannerLoading])

  useLayoutEffect(() => {
    if (window.location.pathname === '/web') {
      store.dispatch(setIsSkeleton(true))
    }
  }, [])

  useEffect(() => {
    return TmapApp.utils.addNativeEventListener('onResume', () => {
      // handleOnResume()
      // focusManager.setFocused(true)
      // setIsResumed(true)
      dispatch(setIsSkeleton(false))
      setTimeout(() => store.dispatch(setIsSkeleton(true)), 1500)
    })
  }, [dispatch])

  useEffect(() => {
    return TmapApp.utils.addNativeEventListener('onRefresh', () => {
      window.location.reload()
    })
  }, [])

  useEffect(() => {
    if (!isMainLoading) {
      tmapLogClient('expose').setPageId('/my').send()
    }
  }, [isMainLoading, tmapLogClient])

  useEffect(() => {
    const { extraObject, pageIdInfo } = pageIdContext
    const encodedLogBoxObject = encodeURIComponent(
      JSON.stringify({
        activeArea: extraObject.activeArea,
        logbox: extraObject.logbox,
      })
    )
    if (TmapApp.env.appVersion !== '0.0.0') {
      // 마이 메인이 항상 떠있는 웹뷰라 스킴으로 진입했을 때 히스토리가 쌓이거나 밑에 메인 페이지가 한 번 떠 깔리는 이슈 있음
      // AOS의 경우 replace 해도 히스토리가 쌓이는 이슈 있어 로그 정보를 가지고있는 url을 스토어에 저장 후 history.back으로 돌아가주고 (0번째 페이지로 가기 위함)
      // 저장된 url이 있으면 replace 해주도록해서 히스토리가 쌓이지 않도록 적용
      if (TmapApp.env.isAndroid) {
        if (pageIdInfo) {
          let path = `/web?extra=${encodedLogBoxObject}`
          store.dispatch(setSchemeURL(path))
          window.history.back()
        }
        if (storageUtil.getItem(STORAGE_SCHEME_URL)) {
          const schemeUrl = storageUtil.getItem(STORAGE_SCHEME_URL) as string
          window.location.replace(schemeUrl)
          setTimeout(() => store.dispatch(setSchemeURL('')), 0)
        }
      } else {
        if (pageIdInfo) {
          window.location.replace(`/web?extra=${encodedLogBoxObject}`)
        }
      }
    }
  }, [pageIdContext])

  return (
    <div className={styles.wrapper}>
      {isMainLoading ? (
        <MainSkeleton />
      ) : (
        <TmapLogContextProvider pageId={'/my'}>
          <div className={styles.interfaceArea} />
          <UserProfile
            profileImage={mockData.profileImage}
            name={mockData.name}
            point={mockData.point}
            carNumber={mockData.carNumber}
            isVerified={isVerified}
            carInfoTags={mockData.carInfoTags}
          />
          <VehicleInfo
            carNumber={'123가1234'}
            vehicleType={'승용차'}
            fuelType={'휘발유'}
            parking={true}
          />
          <PlaceReview reviewData={mockReviewData} />
          <AdsBanner
            data={bannerData}
            isLoading={isBannerLoading}
          />
          <ServiceList />
        </TmapLogContextProvider>
      )}
    </div>
  )
}
