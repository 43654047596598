import Image from 'next/image'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import reaction3 from '@/assets/images/face_curios_1.svg'
import reaction2 from '@/assets/images/face_smile_2.svg'
import reaction1 from '@/assets/images/face_wink_3.svg'
import ReviewPromotionBanner from '@/assets/images/img_my_review.svg'
import { useOpenService } from '@/hooks'

import * as styles from './placeReview.css'

interface Props {
  reviewData: any
}

function PlaceReview(props: Props) {
  const { reviewData } = props
  const {
    needPopup,
    reviewCount,
    reviewReaction1,
    reviewReaction2,
    reviewReaction3,
    activeReviewCount,
    review,
  } = reviewData.data
  const { tripDateTime, availableTripReview, poiId, poiName, reviewId, newReview, reviewGuide } =
    review
  const textRef = useRef<HTMLElement | null>(null)
  const openService = useOpenService()
  const [isNarrow, setIsNarrow] = useState(false)

  const moveToReviewHome = useCallback(() => {
    openService('tmap://place?productid=review&pageid=home')
  }, [openService])

  const moveToReview = useCallback(() => {
    if (newReview) {
      openService(
        `tmap://place?productid=review&pageid=write&extra=${encodeURIComponent(JSON.stringify({ poiId }))}`
      )
    } else {
      openService(
        `tmap://place?productid=review&pageid=write&extra=${encodeURIComponent(
          JSON.stringify({
            poiId,
            reviewId,
          })
        )}`
      )
    }
  }, [newReview, openService, poiId, reviewId])

  useEffect(() => {
    const handleResize = () => {
      setIsNarrow(window.innerWidth <= 320)
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (textRef.current) {
      textRef.current.textContent = isNarrow ? '...' : reviewReaction3
    }
  }, [isNarrow, reviewReaction3])

  const displayReactions = useMemo(() => {
    return (
      <div className={styles.reactionWrapper}>
        내 리뷰 반응
        <div className={styles.reactions}>
          <div>
            <Image
              src={reaction1}
              alt={''}
            />
            <span>{reviewReaction1 > 99 ? '99+' : reviewReaction1}</span>
            <span className={styles.pipe}>|</span>
          </div>
          <div>
            <Image
              src={reaction2}
              alt={''}
            />
            <span>{reviewReaction2 > 99 ? '99+' : reviewReaction2}</span>
            <span className={styles.pipe}>|</span>
          </div>
          <div className={styles.lastReaction}>
            <Image
              className={styles.lastReactionImage}
              src={reaction3}
              alt={''}
            />
            <span ref={textRef}>{reviewReaction3 > 99 ? '99+' : reviewReaction3}</span>
          </div>
        </div>
      </div>
    )
  }, [reviewReaction1, reviewReaction2, reviewReaction3])

  const moreReviewBtn = useMemo(() => {
    return (
      <button
        className={styles.moreReviewBtn}
        onClick={moveToReviewHome}
      >
        <>
          작성 가능한 &nbsp;<span>{activeReviewCount}개</span>&nbsp;리뷰 쓰기
        </>
      </button>
    )
  }, [activeReviewCount, moveToReviewHome])

  const displayReviewModule = useMemo(() => {
    return (
      <>
        <div
          className={styles.reviewModuleWrapper}
          onClick={(event) => {
            event.stopPropagation()
            moveToReview()
          }}
        >
          <p>{poiName}</p>
          <p>{newReview ? '방문 경험을 공유해 주세요' : '리뷰 작성을 완료해 주세요'}</p>
          {availableTripReview && <div className={styles.tripDateTag}>{tripDateTime} 주행</div>}
          <button
            className={styles.reviewBtn}
            onClick={(event) => {
              event.stopPropagation()
              moveToReview()
            }}
          >
            {reviewGuide}
          </button>
        </div>
        {activeReviewCount >= 2 && moreReviewBtn}
      </>
    )
  }, [
    poiName,
    newReview,
    availableTripReview,
    tripDateTime,
    reviewGuide,
    activeReviewCount,
    moreReviewBtn,
    moveToReview,
  ])

  const displayReview = useMemo(() => {
    if (!reviewCount && !activeReviewCount) {
      return (
        <button
          className={styles.banner}
          onClick={moveToReviewHome}
        >
          <Image
            style={{ width: '100%' }}
            src={ReviewPromotionBanner}
            alt={''}
          />
        </button>
      )
    } else {
      return (
        <>
          {displayReactions}
          {displayReviewModule}
        </>
      )
    }
  }, [reviewCount, activeReviewCount, displayReactions, displayReviewModule, moveToReviewHome])

  if (!needPopup) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <p>장소 리뷰 {reviewCount > 0 && <span className={styles.count}>{reviewCount}</span>}</p>
        <button onClick={moveToReviewHome}>
          <p className={styles.btn_more}>더보기 </p>
        </button>
      </div>
      {displayReview}
    </div>
  )
}

export { PlaceReview }
