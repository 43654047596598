import Image from 'next/image'
import process from 'process'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import BizBanner from '@/assets/images/img_my_menu_biz.svg'
import { getSettingsState } from '@/features/store/settings'
import { useOpenService, useOpenServiceUrl } from '@/hooks'
import { ServiceListType, envServiceUrl, staticBizPlaceBanner, staticServiceList } from '@/utils'

import * as styles from './serviceList.css'

function ServiceList() {
  const { remoteConfigServiceList, bizPlaceBanner } = useSelector(getSettingsState)
  const serviceList = remoteConfigServiceList.length
    ? JSON.parse(remoteConfigServiceList)
    : staticServiceList
  const bizPlaceStatus = bizPlaceBanner.length ? JSON.parse(bizPlaceBanner) : staticBizPlaceBanner
  const openService = useOpenService()
  const openServiceUrl = useOpenServiceUrl()
  const [hasRedDot, setHasRedDot] = useState(true)
  const env = process.env.NEXT_PUBLIC_TARGET

  const handleClick = useCallback(
    (serviceUrl: string) => {
      openService(serviceUrl)
    },
    [openService]
  )

  const handleClickTmapBizInfo = useCallback(() => {
    switch (env) {
      case 'dev':
        openServiceUrl(envServiceUrl.tmapBizInfo.dev, true)
        break
      case 'dtg':
        openServiceUrl(envServiceUrl.tmapBizInfo.stg, true)
        break
      case 'stg':
        openServiceUrl(envServiceUrl.tmapBizInfo.stg, true)
        break
      case 'rtg':
        openServiceUrl(envServiceUrl.tmapBizInfo.prd, true)
        break
      case 'prd':
        openServiceUrl(envServiceUrl.tmapBizInfo.prd, true)
        break
    }
  }, [env, openServiceUrl])

  return (
    <div className={styles.wrapper}>
      {serviceList.map((group: ServiceListType) => {
        return (
          <div key={group.groupCode}>
            <p className={styles.groupTitle}>{group.groupName}</p>
            {group.groupList.map((service) => (
              <button
                key={service.name}
                className={`${styles.serviceBtn} ${hasRedDot ? styles.redDotVariants.reddot : ''}`}
                onClick={() => handleClick(service.url)}
              >
                {service.name}
              </button>
            ))}
          </div>
        )
      })}
      {bizPlaceStatus.isShow && (
        <button
          className={styles.bizPlaceBannerWrapper}
          onClick={() => handleClick(bizPlaceStatus.url)}
        >
          <div>
            <p className={styles.bizPlaceTitle}>비즈 플레이스</p>
            <p>티맵에 업체 등록하고 관리하기</p>
          </div>
          <Image
            src={BizBanner}
            alt={''}
          />
        </button>
      )}

      <button
        className={styles.tmapBizInfo}
        onClick={handleClickTmapBizInfo}
      >
        티맵모빌리티(주) 사업자정보
      </button>
    </div>
  )
}

export { ServiceList }
